<template>
  <h2>Контекст this</h2>
  <p>По этой теме очень любят задавать вопросы на собеседованиях, поэтому лучше досконально разобраться с этим. Предлагаю ознакомиться с видео по этой теме:</p>
  <div class="iframe-16-9">
    <iframe src="https://www.youtube.com/embed/UGapN-hrekw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <p>Обратите внимание, что в видео не используются стрелочные функции. Это не случайно. В стрелочных функциях нет контекста this. То есть:</p>
  <Code lang="js" :code="`
    const a = {
        name: 'Vasya',
        foo: function() {
            console.log(this.name)
        },
        bar: () => {
            console.log(this.name)
        }
    }
    a.foo() // Vasya
    a.bar() // undefined
  `"
  />
  <p>При этом в браузерном JavaScript this - это по умолчанию объект Window, а в Node.js - пустой объект</p>
</template>

<script>
import Code from '@/components/ui/Code'

export default {
  components: {
    Code
  },
  props: ['task']
}
</script>
