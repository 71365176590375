import Simple from '@/components/task-templates/Simple'
import CodeTest from '@/components/task-templates/CodeTest'
import ContextIntro from './ContextIntro'

export default [
  {
    component: Simple,
    name: 'Чтение простых ключей',
    text: "Дан объект \\(a\\). Выведите через пробел значения по ключам 'name' и 'age'",
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Значения ключей через пробел',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: 'Vasya 20'
      },
      {
        input: "{ age: 30, name: 'Petya' }",
        output: 'Petya 30'
      },
      {
        input: '{}',
        output: 'undefined undefined'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чтение непростых ключей',
    text: "Дан объект \\(a\\). Выведите через пробел значения по ключам 'n a m e' и 'a g e'",
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Значения ключей через пробел',
    examples: [
      {
        input: "{ 'n a m e': 'Vasya', 'a g e': 20 }",
        output: 'Vasya 20'
      },
      {
        input: "{ 'a g e': 30, 'n a m e': 'Petya' }",
        output: 'Petya 30'
      },
      {
        input: '{}',
        output: 'undefined undefined'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чтение переменных ключей',
    text: 'Дан объект \\(a\\) и строки \\(k1\\) и \\(k2\\). Выведите через пробел значения по ключам \\(k1\\) и \\(k2\\)',
    note: null,
    input: 'Объект \\(a\\), строки \\(k1\\) и \\(k2\\)',
    output: 'Значения ключей через пробел',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }, 'name', 'age'",
        output: 'Vasya 20'
      },
      {
        input: "{ a: 10, b: 20, c: 30 }, 'c', 'b'",
        output: '30 20'
      },
      {
        input: "{}, 'k1', 'k2'",
        output: 'undefined undefined'
      }
    ]
  },
  {
    component: Simple,
    name: 'Получение массива ключей',
    text: 'Дан объект \\(a\\). Выведите массив его ключей, используя функцию Object.keys',
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Массив ключей',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: "['name', 'age']"
      },
      {
        input: '{ a: 10, b: 20, c: 30 }',
        output: "['a', 'b', 'c']"
      },
      {
        input: '{}',
        output: '[]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Получение массива значений',
    text: 'Дан объект \\(a\\). Выведите массив его значений, используя функцию Object.values',
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Массив значений',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: "['Vasya', 20]"
      },
      {
        input: '{ a: 10, b: 20, c: 30 }',
        output: '[10, 20, 30]'
      },
      {
        input: '{}',
        output: '[]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Получение массива пар ключ-значение',
    text: 'Дан объект \\(a\\). Выведите массив его пар ключ-значение, используя функцию Object.entries',
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Массив пар ключ-значение',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: "[['name', 'Vasya'], ['age', 20]]"
      },
      {
        input: '{ a: 10, b: 20, c: 30 }',
        output: "[['a', 10], ['b', 20], ['c', 30]]"
      },
      {
        input: '{}',
        output: '[]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Уникальные символы',
    text: 'Дана строка \\(s\\). Выведите массив, который содержит только различные символы строки \\(s\\)',
    note: 'Используя объект, алгоритм будет более эффективным, чем в решении средствами массивов',
    input: 'Строка \\(s\\)',
    output: 'Полученный массив',
    examples: [
      {
        input: "''",
        output: '[]'
      },
      {
        input: "'abc'",
        output: "['a', 'b', 'c']"
      },
      {
        input: "'test'",
        output: "['t', 'e', 's']"
      }
    ]
  },
  {
    component: Simple,
    name: 'Перебор ключей через for-in',
    text: 'Дан объект \\(a\\). Используя цикл for-in выведите каждую пару ключ-значение через пробел, по одной паре на строку',
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Пары ключ-значение',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: 'name Vasya\nage 20'
      },
      {
        input: '{ a: 10, b: 20, c: 30 }',
        output: 'a 10\nb 20\nc 30'
      },
      {
        input: '{}',
        output: ''
      }
    ]
  },
  {
    component: Simple,
    name: 'Перебор пар через for-of',
    text: 'Дан объект \\(a\\). Используя цикл for-of и функцию Object.entries выведите каждую пару ключ-значение через пробел, по одной паре на строку',
    note: 'Нужно использовать деструктуризацию в for-of',
    input: 'Объект \\(a\\)',
    output: 'Пары ключ-значение',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: 'name Vasya\nage 20'
      },
      {
        input: '{ a: 10, b: 20, c: 30 }',
        output: 'a 10\nb 20\nc 30'
      },
      {
        input: '{}',
        output: ''
      }
    ]
  },
  {
    component: Simple,
    name: 'Проверка наличия ключа',
    text: "Дан объект \\(a\\) и строка \\(k\\). Используя оператор in проверьте, есть ли в объекте ключ 'name' и ключ \\(k\\)",
    note: null,
    input: 'Объект \\(a\\) и строка \\(k\\)',
    output: 'Булевы значения на разных строках',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }, 'a'",
        output: 'true\nfalse'
      },
      {
        input: "{ a: 10, b: 20, c: 30 }, 'c'",
        output: 'false\ntrue'
      },
      {
        input: "{}, 'k'",
        output: 'false\nfalse'
      }
    ]
  },
  {
    component: Simple,
    name: 'Проверка наличия ключей из массива',
    text: 'Дан объект \\(a\\) и массив строк \\(keys\\). Для каждого элемента из \\(keys\\) проверьте, есть ли такой ключ в объекте. Выведите массив такой же длины, как и \\(keys\\), состоящий из логических значений (true или false) - есть или нет такой ключ в объекте',
    note: null,
    input: 'Объект \\(a\\) и массив строк \\(keys\\)',
    output: 'Полученный массив',
    examples: [
      {
        input: "{ a: 20, b: 10, 0: 30 }, ['b', 'x', '0']",
        output: '[true, false, true]'
      },
      {
        input: "{ name: 'Вася', age: 20 }, ['age', 'age', 'name']",
        output: '[true, true, true]'
      },
      {
        input: "{}, ['a', 'a', 'a']",
        output: '[false, false, false]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удаление ключа',
    text: "Дан объект \\(a\\) и строка \\(k\\). Удалите ключ 'name' и ключ \\(k\\) из объекта",
    note: null,
    input: 'Объект \\(a\\) и строка \\(k\\)',
    output: 'Изменённый объект',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }, 'a'",
        output: '{ age: 20 }'
      },
      {
        input: "{ a: 10, b: 20, c: 30 }, 'c'",
        output: '{ a: 10, b: 20 }'
      },
      {
        input: "{}, 'k'",
        output: '{}'
      }
    ]
  },
  {
    component: Simple,
    name: 'Создание объекта',
    text: "Даны два значения \\(v1\\) и \\(v2\\). Создайте и выведите объект, у которого по ключу 'name' будет значение \\(v1\\), а по ключу 'age' значение \\(v2\\)",
    note: null,
    input: '\\(v1\\) и \\(v2\\) - какие-то значения',
    output: 'Созданный объект',
    examples: [
      {
        input: "'Vasya', 20",
        output: "{ name: 'Vasya', age: 20 }"
      },
      {
        input: 'undefined, false',
        output: '{ name: undefined, age: false }'
      },
      {
        input: 'null, [true]',
        output: '{ name: null, age: [true] }'
      }
    ]
  },
  {
    component: Simple,
    name: 'Создание объекта с совпадением ключа и значения',
    text: "Даны два значения \\(name\\) и \\(age\\). Создайте и выведите объект, у которого по ключу 'name' будет значение \\(name\\), а по ключу 'age' значение \\(age\\)",
    note: null,
    input: '\\(name\\) и \\(age\\) - какие-то значения',
    output: 'Созданный объект',
    examples: [
      {
        input: "'Vasya', 20",
        output: "{ name: 'Vasya', age: 20 }"
      },
      {
        input: 'undefined, false',
        output: '{ name: undefined, age: false }'
      },
      {
        input: 'null, [true]',
        output: '{ name: null, age: [true] }'
      }
    ]
  },
  {
    component: Simple,
    name: 'Добавление и перезапись',
    text: "Дан объект \\(a\\). Добавьте или перезапишите значение по ключу 'name' на значение 'Vasya'",
    note: null,
    input: '\\(a\\) - объект',
    output: 'Изменённый объект',
    examples: [
      {
        input: '{}',
        output: "{ name: 'Vasya' }"
      },
      {
        input: '{ age: 20 }',
        output: "{ age: 20, name: 'Vasya' }"
      },
      {
        input: "{ name: 'Petya', age: 20 }",
        output: "{ name: 'Vasya', age: 20 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Добавление и перезапись по динамическому ключу',
    text: 'Дан объект \\(a\\), строка \\(k\\) и какое-то значение \\(v\\). Добавьте или перезапишите значение по ключу \\(k\\) на значение \\(v\\)',
    note: null,
    input: '\\(a\\) - объект, \\(k\\) - строка, \\(v\\) - значение',
    output: 'Изменённый объект',
    examples: [
      {
        input: "{}, 'name', 'Vasya'",
        output: "{ name: 'Vasya' }"
      },
      {
        input: "{ name: 'Vasya' }, 'age', 20",
        output: "{ name: 'Vasya', age: 20 }"
      },
      {
        input: "{ name: 'Petya', age: 20 }, 'name', 'Vasya'",
        output: "{ name: 'Vasya', age: 20 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Изменение значения по ключу',
    text: "Дан объект \\(a\\), содержащий ключи 'age' и 'salary'. Увеличьте значение по ключу 'age' на 1, а значение по ключу 'salary' на 2000",
    note: null,
    input: '\\(a\\) - объект',
    output: 'Изменённый объект',
    examples: [
      {
        input: '{ age: 20, salary: 10000 }',
        output: '{ age: 21, salary: 12000 }'
      },
      {
        input: "{ name: 'Vasya', age: 30, salary: 20000 }",
        output: "{ name: 'Vasya', age: 31, salary: 22000 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Изменение с условием',
    text: "Дан объект \\(a\\) с ключами 'a', и 'b' (с целочисленными значениями). Если значение по ключу 'a' больше, чем по ключу 'b', то увеличьте значение по ключу 'a' на значение по ключу 'b', иначе оба значения увеличьте на 1. Выведите объект",
    note: null,
    input: 'Задан объект \\(a\\)',
    output: 'Изменённый объект',
    examples: [
      {
        input: '{ a: 20, b: 10 }',
        output: '{ a: 30, b: 10 }'
      },
      {
        input: '{ a: 20, b: 30 }',
        output: '{ a: 21, b: 31 }'
      },
      {
        input: '{ a: 20, b: 20 }',
        output: '{ a: 21, b: 21 }'
      }
    ]
  },
  {
    component: Simple,
    name: 'Создание с помощью цикла',
    text: 'Дан массив \\(a\\) со строковыми значениями. Создайте и выведите объект, у которого ключами и значениями будут элементы \\(a\\) (ключи будут совпадать со значениями)',
    note: null,
    input: '\\(a\\) - массив строк',
    output: 'Созданный объект',
    examples: [
      {
        input: "['a', 'b', 'c']",
        output: "{ a: 'a', b: 'b', c: 'c' }"
      },
      {
        input: '[]',
        output: '{}'
      }
    ]
  },
  {
    component: Simple,
    name: 'Числовые ключи',
    text: 'Дано число \\(n\\). Создайте объект, ключами которого будут числа от 0 (включительно) до \\(n\\) (не включительно), а значениями числа \\(n\\). Выведите объект',
    note: 'Обратите внимание, что даже в этом случае ключи становятся строками',
    input: '\\(n\\) - целое неотрицательное',
    output: 'Полученный объект',
    examples: [
      {
        input: '0',
        output: '{}'
      },
      {
        input: '1',
        output: "{ '0': 1 }"
      },
      {
        input: '3',
        output: "{ '0': 3, '1': 3, '2': 3 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Буквенные ключи',
    text: 'Дано число \\(n\\). Создайте объект, ключами которого будут \\(n\\) первых строчных букв алфавита, а значениями их коды в таблице ascii',
    note: null,
    input: '\\(n\\) - целое неотрицательное',
    output: 'Полученный объект',
    examples: [
      {
        input: '0',
        output: '{}'
      },
      {
        input: '1',
        output: '{ a: 97 }'
      },
      {
        input: '3',
        output: '{ a: 97, b: 98, c: 99 }'
      }
    ]
  },
  {
    component: Simple,
    name: 'Создание из строки',
    text: 'Дана строка \\(s\\). Создайте объект, ключами которого будут символы из строки \\(s\\), а значениями их коды в таблице ascii. Выведите объект',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученный объект',
    examples: [
      {
        input: "''",
        output: '{}'
      },
      {
        input: "'z'",
        output: '{ z: 122 }'
      },
      {
        input: "'test'",
        output: '{ t: 116, e: 101, s: 115 }'
      }
    ]
  },
  {
    component: Simple,
    name: 'Создание по массиву пар',
    text: 'Дан массив \\(a\\) с парами значений (каждая пара - массив длины 2). Создайте и выведите объект, у которого ключами будут первые элементы из пары, а значениями вторые',
    note: 'Нужно использовать деструктуризацию в for-of',
    input: '\\(a\\) - массив пар',
    output: 'Созданный объект',
    examples: [
      {
        input: "[['a', 10], ['b', 20]]",
        output: '{ a: 10, b: 20 }'
      },
      {
        input: "[['name', 'Vasya'], ['age', 20]]",
        output: "{ name: 'Vasya', age: 20 }"
      },
      {
        input: '[]',
        output: '{}'
      }
    ]
  },
  {
    component: Simple,
    name: 'Замена if',
    text: "Дана строка \\(name\\) с одним из значений: 'Vasya', 'Petya' или 'Misha'. Выведите должность персонажа ('programer', 'QA' и 'manager' соответственно)",
    note: 'Нужно решить, не используя if',
    input: '\\(name\\) - строка',
    output: 'Профессия',
    examples: [
      {
        input: "'Vasya'",
        output: 'programer'
      },
      {
        input: "'Petya'",
        output: 'QA'
      },
      {
        input: "'Misha'",
        output: 'manager'
      }
    ]
  },
  {
    component: Simple,
    name: 'Подсчёт с помощью объекта',
    text: 'Дана строка \\(s\\). Создайте объект, ключами которого будут символы из строки \\(s\\), а значениями количество вхождений соответствующего символа в строку',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Полученный объект',
    examples: [
      {
        input: "''",
        output: '{}'
      },
      {
        input: "'abc'",
        output: '{ a: 1, b: 1, c: 1 }'
      },
      {
        input: "'test'",
        output: '{ t: 2, e: 1, s: 1 }'
      }
    ]
  },
  {
    component: Simple,
    name: 'Замена в массиве из объекта',
    text: 'Дан массив строк \\(a\\) и объект \\(d\\) (со строковыми значениями). Произведите замену тех элементов в массиве \\(a\\), для которых есть соответствующий ключ в объекте \\(d\\), на значения по этому ключу из объекта. Выведите модифицированный массив',
    note: null,
    input: 'Массив строк \\(a\\) и объект \\(d\\)',
    output: 'Изменённый массив',
    examples: [
      {
        input: "['t', 'e', 's', 't'], { t: 'a', e: 'b' }",
        output: "['a', 'b', 's', 'a']"
      },
      {
        input: "['a', 'a', 'a'], { a: 'b', b: 'a' }",
        output: "['b', 'b', 'b']"
      },
      {
        input: "['t', 'e', 's', 't'], { x: 't' }",
        output: "['t', 'e', 's', 't']"
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен ключей и значений',
    text: 'Дан объект \\(a\\) со строковыми значениями. Создайте новый объект, ключами которого будут значения \\(a\\), а значениями - ключи \\(a\\). Выведите новый объект',
    note: null,
    input: 'Объект \\(a\\) со строковыми значениями',
    output: 'Полученный объект',
    examples: [
      {
        input: "{ a: 'x', b: 'y', c: 'z' }",
        output: "{ x: 'a', y: 'b', z: 'c' }"
      },
      {
        input: "{ a: 'a', b: 'b', c: 'c' }",
        output: "{ a: 'a', b: 'b', c: 'c' }"
      },
      {
        input: "{ a: 'a', b: 'a', c: 'a' }",
        output: "{ a: 'c' }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Объект из линейного массива',
    text: 'Дан массив строк \\(a\\) чётной длины. Создайте объект, в котором ключами будет каждый второй элемент, начиная с первого, а значениями каждый второй, начиная со второго',
    input: 'Массив \\(a\\) чётной длины',
    output: 'Полученный объект',
    note: null,
    examples: [
      {
        input: "['a', 'b']",
        output: "{ a: 'b' }"
      },
      {
        input: "['a', 'a', 'b', 'b']",
        output: "{ a: 'a', b: 'b' }"
      },
      {
        input: "['a', 'b', 'a', 'b']",
        output: "{ a: 'b' }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимум и сумма по значениям',
    text: 'Дан объект \\(a\\) с числовыми значениями. Выведите максимальное значение и сумму всех значений',
    input: 'Объект \\(a\\) (непустой)',
    output: 'Максимум и сумма через пробел',
    note: null,
    examples: [
      {
        input: '{ a: 5, b: 7, c: 3 }',
        output: '7 15'
      },
      {
        input: '{ a: -3, b: -5, c: -1 }',
        output: '-1 -9'
      },
      {
        input: '{ a: 10, a: 20, a: -3 }',
        output: '-3 -3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Ключ максимума',
    text: 'Дан объект \\(a\\) с числовыми значениями. Выведите ключ, соответствующий максимальному значению (первый попавшийся, если их несколько)',
    note: null,
    input: 'Объект \\(a\\) (непустой)',
    output: 'Ключ максимума',
    examples: [
      {
        input: '{ a: 5, b: 7, c: 3 }',
        output: 'b'
      },
      {
        input: '{ a: -3, b: -5, c: -1 }',
        output: 'c'
      },
      {
        input: '{ a: 10, b: 20, b: 10 }',
        output: 'a'
      }
    ]
  },
  {
    component: Simple,
    name: 'Создание из массивов с ключами и значениями',
    text: 'Даны массивы \\(a\\) и \\(b\\) одинаковой длины. Создайте и выведите объект, у которого ключи взяты из \\(a\\), а значения из \\(b\\)',
    note: null,
    input: 'Массивы \\(a\\) и \\(b\\) со строковыми значениями',
    output: 'Полученный объект',
    examples: [
      {
        input: "['key1', 'key2'], ['value1', 'value2']",
        output: "{ key1: 'value1', key2: 'value2' }"
      },
      {
        input: "['a', 'b'], ['c', 'd']",
        output: "{ a: 'c', b: 'd' }"
      },
      {
        input: "['a', 'a', 'a'], ['b', 'c', 'd']",
        output: "{ a: 'd' }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Объект из массива объектов',
    text: "Дан массив объектов \\(a\\). В каждом объекте массива есть ключи 'name' и 'value'. Создайте и выведите объект, у которого ключи берутся из значений 'name', а значения из значений 'value'",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: 'Полученный объект',
    examples: [
      {
        input: "[\n    { name: 'key1', value: 'value1' },\n    { name: 'key2', value: 'value2' }\n]",
        output: "{ key1: 'value1', key2: 'value2' }"
      },
      {
        input: "[\n    { name: 'a', value: 'b' },\n    { name: 'c', value: 'd' }\n]",
        output: "{ a: 'b', c: 'd' }"
      },
      {
        input: "[\n    { name: 'a', value: 'b' },\n    { name: 'a', value: 'c' }\n]",
        output: "{ a: 'c' }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Массив из объекта',
    text: 'Дан объект \\(a\\). Создайте и выведите массив, в котором каждый второй элемент, начиная с первого - это ключ, а каждый второй, начиная со второго - значение',
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Полученный массив',
    examples: [
      {
        input: "{ key1: 'value1', key2: 'value2' }",
        output: "['key1', 'value1', 'key2', 'value2']"
      },
      {
        input: "{ a: 'b', c: 'd' }",
        output: "['a', 'b', 'c', 'd']"
      },
      {
        input: "{ a: 'b', a: 'c' }",
        output: "['a', 'c']"
      }
    ]
  },
  {
    component: Simple,
    name: 'Простая деструктуризация объекта',
    text: "Дан объект \\(a\\) с ключами 'name' и 'age'. С помощью деструктуризации запишите значение ключа 'name' в переменную \\(name\\), а значение ключа 'age' в переменную \\(age\\)",
    note: null,
    input: 'Объект \\(a\\)',
    output: '\\(name\\) и \\(age\\) через пробел',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: 'Vasya 20'
      },
      {
        input: '{}',
        output: 'undefined undefined'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация с указанием имени',
    text: "Дан объект \\(a\\) с ключами 'v1' и 'v2'. С помощью деструктуризации запишите значение ключа 'v1' в переменную \\(name\\), а значение ключа 'v2' в переменную \\(age\\)",
    note: null,
    input: 'Объект \\(a\\)',
    output: '\\(name\\) и \\(age\\) через пробел',
    examples: [
      {
        input: "{ v1: 'Vasya', v2: 20 }",
        output: 'Vasya 20'
      },
      {
        input: '{}',
        output: 'undefined undefined'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация + rest',
    text: "Дан объект \\(a\\) с ключом 'id'. С помощью деструктуризации и оператора rest запишите значение ключа 'id' в переменную \\(id\\), а объект со всеми остальными ключами в переменную \\(other\\)",
    note: null,
    input: 'Объект \\(a\\)',
    output: '\\(id\\) и \\(other\\) через пробел',
    examples: [
      {
        input: "{ id: 1, name: 'Vasya' }",
        output: "1 { name: 'Vasya' }"
      },
      {
        input: '{ id: 2, age: 20 }',
        output: '2 { age: 20 }'
      },
      {
        input: '{ id: 3 }',
        output: '3 {}'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация со вложенностью',
    text: "Дан объект \\(a\\) с ключом 'response', значением которого является объект с ключом 'data'. С помощью деструктуризации запишите значение ключа 'data' в переменную \\(data\\)",
    note: null,
    input: 'Объект \\(a\\)',
    output: '\\(data\\)',
    examples: [
      {
        input: "{ response: { data: 'ok' } }",
        output: 'ok'
      },
      {
        input: "{\n    method: 'GET',\n    response: {\n        status: 200,\n        data: [1, 2, 3]\n    }\n}",
        output: '[1, 2, 3]'
      },
      {
        input: '{ response: {} }',
        output: 'undefined'
      }
    ]
  },
  {
    component: Simple,
    name: 'Добавление или перезапись ключа без мутации с помощью spread',
    text: "Дан объект \\(a\\). Создайте новый объект со всеми ключами \\(a\\), добавив, или перезаписав значение по ключу 'name' на 'Vasya', используя оператор spread",
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Полученный объект',
    examples: [
      {
        input: '{ age: 20 }',
        output: "{ age: 20, name: 'Vasya' }"
      },
      {
        input: "{ name: 'Petya', salary: 8000 }",
        output: "{ name: 'Vasya', salary: 8000 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Перезапись дефолтных значений с помощью spread',
    text: "Дан объект \\(a\\). Создайте новый объект со всеми ключами \\(a\\), но если нет ключа 'name', то взять значение 'Vasya', используя оператор spread",
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Полученный объект',
    examples: [
      {
        input: '{ age: 20 }',
        output: "{ name: 'Vasya', age: 20 }"
      },
      {
        input: "{ name: 'Petya', salary: 8000 }",
        output: "{ name: 'Petya', salary: 8000 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Объединение объектов с помощью spread',
    text: 'Даны объекты \\(a\\) и \\(b\\). Создайте новый объект со всеми ключами \\(a\\) и \\(b\\). Ключи из \\(b\\) считать приоритетными (при конфликте имён брать значения из \\(b\\))',
    note: null,
    input: 'Объекты \\(a\\) и \\(b\\)',
    output: 'Полученный объект',
    examples: [
      {
        input: "{ name: 'Vasya' }, { age: 20 }",
        output: "{ name: 'Vasya', age: 20 }"
      },
      {
        input: "{ name: 'Petya' }, { name: 'Vasya' }",
        output: "{ name: 'Vasya' }"
      },
      {
        input: "{ name: 'Petya', age: 20 },\n{ name: 'Vasya', salary: 8000 }",
        output: "{ name: 'Vasya', age: 20, salary: 8000 }"
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация и spread',
    text: "Даны объекты \\(a\\) и \\(b\\). С помощью деструктуризации запишите в переменные \\(name\\) и \\(age\\) значения по ключами 'name' и 'age' из объединения \\(a\\) и \\(b\\). Ключи из \\(b\\) считать приоритетными",
    note: null,
    input: 'Объекты \\(a\\) и \\(b\\)',
    output: '\\(name\\) и \\(age\\) через пробел',
    examples: [
      {
        input: "{ name: 'Vasya' }, { age: 20 }",
        output: 'Vasya 20'
      },
      {
        input: "{ name: 'Petya' }, { name: 'Vasya' }",
        output: 'Vasya undefined'
      },
      {
        input: "{ name: 'Petya', age: 20 },\n{ name: 'Vasya', salary: 8000 }",
        output: 'Vasya 20'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация с дефолтными значениями',
    text: "Дан объект \\(a\\). С помощью деструктуризации запишите в переменные \\(name\\) и \\(age\\) значения по ключами 'name' и 'age' из \\(a\\). При отсутствии соответствующих ключей взять 'Vasya' и 20 соответственно",
    note: null,
    input: 'Объект \\(a\\)',
    output: '\\(name\\) и \\(age\\) через пробел',
    examples: [
      {
        input: "{ name: 'Petya', age: 30 }",
        output: 'Petya 30'
      },
      {
        input: "{ name: 'Petya' }",
        output: 'Petya 20'
      },
      {
        input: '{ salary: 8000 }',
        output: 'Vasya 20'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация в for-of',
    text: "Дан массив объектов \\(a\\). В каждом объекте есть ключи 'name' и 'age'. С помощью деструктуризации в for-of выводите значения этих ключей по одной паре в строке",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: '\\(name\\) и \\(age\\) через пробел для каждого элемента',
    examples: [
      {
        input: "[\n    { name: 'Vasya', age: 20 },\n    { name: 'Petya', age: 30 },\n    { name: 'Oksana', age: 18 },\n    { name: 'Mikhail', age: 35 }\n]",
        output: 'Vasya 20\nPetya 30\nOksana 18\nMikhail 35'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация в параметрах функции',
    text: "Дан объект с ключами 'name' и 'age'. С помощью деструктуризации в параметрах функции выведите значения этих ключей",
    note: null,
    input: 'Объект',
    output: '\\(name\\) и \\(age\\) через пробел',
    examples: [
      {
        input: "{ name: 'Vasya', age: 20 }",
        output: 'Vasya 20'
      },
      {
        input: "{ name: 'Petya', age: 30 }",
        output: 'Petya 30'
      },
      {
        input: '{}',
        output: 'undefined undefined'
      }
    ]
  },
  // f({ id, ...rest })
  {
    component: Simple,
    name: 'Деструктуризация + rest в параметрах функции',
    text: "Дан объект с ключом 'id' и ещё какими-то данными. С помощью деструктуризации в параметрах функции выведите id и объект со всеми остальными данными",
    note: null,
    input: 'Объект',
    output: '\\(id\\) и объект с остальными данными',
    examples: [
      {
        input: "{ id: 1, name: 'Vasya', age: 20 }",
        output: "1 { name: 'Vasya', age: 20 }"
      },
      {
        input: '{ id: 2, salary: 8000 }',
        output: '2 { salary: 8000 }'
      },
      {
        input: '{ id: 3 }',
        output: '3 {}'
      }
    ]
  },
  // const [{ value: a1 }, { value: a2 }] = a
  {
    component: Simple,
    name: 'Деструктуризация массива объектов',
    text: "Дан массив объектов \\(a\\). В каждом объекте есть ключ 'value'. С помощью деструктуризации запишите значение по ключу 'value' из первого объекта в переменную \\(a1\\), а значение по ключу 'value' второго объекта в \\(a2\\)",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: '\\(a1\\) и \\(a2\\)',
    examples: [
      {
        input: '[{ value: 20 }, { value: 30 }]',
        output: '20 30'
      },
      {
        input: '[\n    { value: [] },\n    { value: {} },\n    { value: null }\n]',
        output: '[] {}'
      },
      {
        input: '[{}, {}]',
        output: 'undefined undefined'
      }
    ]
  },
  // const [{ value: a1=0 }, { value: a2=0 }] = a
  {
    component: Simple,
    name: 'Деструктуризация массива объектов с дефолтными значениями',
    text: "Дан массив объектов \\(a\\). В каждом объекте есть ключ 'value'. С помощью деструктуризации запишите значение по ключу 'value' из первого объекта в переменную \\(a1\\), а значение по ключу 'value' второго объекта в \\(a2\\). Если ключа 'value' нет, то записать 0 как значение",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: '\\(a1\\) и \\(a2\\)',
    examples: [
      {
        input: '[{ value: 20 }, { value: 30 }]',
        output: '20 30'
      },
      {
        input: '[\n    { value: [] },\n    { value: {} },\n    { value: null }\n]',
        output: '[] {}'
      },
      {
        input: '[{}, {}]',
        output: '0 0'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    label: '+',
    name: 'Объект - ссылочный тип',
    text: "Напишите функцию \\(linkTest\\), которая принимает два объекта \\(a\\) и \\(b\\). В объекте \\(a\\) запишите по ключу 'name' значение 'Vasya'. Значение переменной \\(b\\) следует изменить, записав в него копию объекта \\(b\\), добавив по ключу 'name' значение 'Vasya'. Выведите \\(a\\) и \\(b\\) на двух строках",
    note: 'Посмотрите внимательно на код, и убедитесь, что понятно, почему результат именно такой',
    examples: [
      {
        input: `
          const a = { age: 20 }
          const b = { age: 20 }
          linkTest(a, b)
          console.log(a)
          console.log(b)
        `,
        output: `
          { age: 20, name: 'Vasya' }
          { age: 20, name: 'Vasya' }
          { age: 20, name: 'Vasya' }
          { age: 20 }
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Переформатирование',
    text: "Дан массив объектов \\(a\\). В каждом объекте есть ключ 'id'. Создайте объект, у которого ключами будут значения 'id' из объектов, а значениями сами эти объекты",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    { id: 1, name: 'Vasya' },\n    { id: 2, name: 'Petya' }\n]",
        output: "{\n    1: { id: 1, name: 'Vasya' },\n    2: { id: 2, name: 'Petya' }\n}"
      },
      {
        input: "[\n    { id: 100, value: 'first' },\n    { id: 200, value: 'second' },\n    { id: 300, value: 'third' }\n]",
        output: "{\n    100: { id: 100, value: 'first' },\n    200: { id: 200, value: 'second' },\n    300: { id: 300, value: 'third' }\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Из двумерного массива в массив объектов',
    text: 'Даны массивы \\(keys\\) и \\(values\\). В массиве \\(keys\\) находятся ключи, а в массиве \\(values\\) массивы значений. Создайте массив из объектов, ключами которых будут значения из \\(keys\\), а значениями соответствующие значения из элементов массива \\(values\\)',
    note: null,
    input: 'Массивы строк \\(keys\\) и \\(values\\)',
    output: 'Получившийся массив',
    examples: [
      {
        input: "['id', 'name', 'age'],\n[\n    [1, 'Vasya', 20],\n    [2, 'Petya', 30]\n]",
        output: "[\n    { id: 1, name: 'Vasya', age: 20 },\n    { id: 2, name: 'Petya', age: 30 }\n]"
      },
      {
        input: "['key', 'value'],\n[\n    ['a', 100],\n    ['b', 200],\n    ['c', 300]\n]",
        output: "[\n    { key: 'a', value: 100 },\n    { key: 'b', value: 200 },\n    { key: 'c', value: 300 }\n]"
      }
    ]
  },
  {
    component: Simple,
    name: 'Группировка',
    text: "Дан массив объектов \\(a\\). В каждом объекте есть ключ 'age'. Создайте объект, у которого ключами будут значения 'age', а значениями массивы с объектами, у которых этот 'age'",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    { id: 1, name: 'Vasya', age: 20 },\n    { id: 2, name: 'Petya', age: 30 },\n    { id: 3, name: 'Ivan', age: 20 },\n    { id: 4, name: 'Fedya', age: 30 }\n]",
        output: "{\n    20: [\n        { id: 1, name: 'Vasya', age: 20 },\n        { id: 3, name: 'Ivan', age: 20 }\n    ],\n    30: [\n        { id: 2, name: 'Petya', age: 30 },\n        { id: 4, name: 'Fedya', age: 30 }\n    ]\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Группировка по результату выражения',
    text: "Дан массив объектов \\(a\\). В каждом объекте есть ключ 'age' - возраст человека. Создайте объект, у которого ключом будет возрастная группа, а значениями массивы с объектами, у которых эта возрастная группа. Возрастные группы определяем по десяткам: 0-9, 10-19, 20-29 и т. д.",
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    { id: 1, name: 'Vasya', age: 21 },\n    { id: 2, name: 'Petya', age: 42 },\n    { id: 3, name: 'Ivan', age: 25 },\n    { id: 4, name: 'Fedya', age: 47 }\n]",
        output: "{\n    '20-29': [\n        { id: 1, name: 'Vasya', age: 21 },\n        { id: 3, name: 'Ivan', age: 25 }\n    ],\n    '40-49': [\n        { id: 2, name: 'Petya', age: 42 },\n        { id: 4, name: 'Fedya', age: 47 }\n    ]\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Массив из объектов в объект из массивов',
    text: 'Дан массив объектов \\(a\\). Во всех объектах одинаковые ключи. Создайте объект, у которого такие же ключи, но значения - это массивы значений по всем объектам',
    note: null,
    input: 'Массив объектов \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    { id: 1, name: 'Vasya', age: 21 },\n    { id: 2, name: 'Petya', age: 42 },\n    { id: 3, name: 'Ivan', age: 25 },\n    { id: 4, name: 'Fedya', age: 47 }\n]",
        output: "{\n    id: [1, 2, 3, 4],\n    name: ['Vasya', 'Petya', 'Ivan', 'Fedya'],\n    age: [21, 42, 25, 47]\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Преобразование массива масивов с ключом вначале',
    text: 'Дан массив массивов \\(a\\) со строковыми значениями. Создайте объект, у которого ключами будут первые элементы внутренних массивов, а значениями остальные элементы',
    note: 'Исходный массив не должен измениться',
    input: 'Массив массивов \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    ['k1', 'v1', 'v2', 'v3'],\n    ['k2', 'v2', 'v4'],\n    ['k3', 'v3', 'v5']\n]",
        output: "{\n    k1: ['v1', 'v2', 'v3'],\n    k2: ['v2', 'v4'],\n    k3: ['v3', 'v5']\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Преобразование объекта масивов в массивы с ключом вначале',
    text: 'Дан объект \\(a\\), состоящий из массивов. Создайте массив, в котором будут массивы, в которых на первом месте ключ объекта, а все остальные значения взяты из значения ключа',
    note: 'Исходный объект не должен измениться',
    input: 'Объект \\(a\\)',
    output: 'Получившийся массив',
    examples: [
      {
        input: "{\n    k1: ['v1', 'v2', 'v3'],\n    k2: ['v2', 'v4'],\n    k3: ['v3', 'v5']\n}",
        output: "[\n    ['k1', 'v1', 'v2', 'v3'],\n    ['k2', 'v2', 'v4'],\n    ['k3', 'v3', 'v5']\n]"
      }
    ]
  },
  {
    component: Simple,
    name: 'Объект из массивов по парам',
    text: 'Дан массив \\(a\\), состоящий из строковых массивов длины 2. Создайте объект, у которого ключами будут первые элементы внутренних массивов, а значениями - массивы со всеми вторыми элементами, которые есть в паре с этим ключом',
    note: null,
    input: 'Массив пар \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    ['k1', 'v1'],\n    ['k1', 'v2'],\n    ['k2', 'v2'],\n    ['k2', 'v3'],\n    ['k3', 'v1'],\n    ['k3', 'v2'],\n    ['k3', 'v3']\n]",
        output: "{\n    k1: ['v1', 'v2'],\n    k2: ['v2', 'v3'],\n    k3: ['v1', 'v2', 'v3']\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Переворот объекта из массивов',
    text: 'Дан объект \\(a\\), состоящий из строковых массивов. Создайте объект, у которого ключами будут значения из массивов, а значениями - массивы ключей \\(a\\), которые содержали это значение',
    note: null,
    input: 'Объект \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "{\n    k1: ['v1', 'v2', 'v3'],\n    k2: ['v2', 'v4'],\n    k3: ['v3', 'v5']\n}",
        output: "{\n    v1: ['k1'],\n    v2: ['k1', 'k2'],\n    v3: ['k1', 'k3'],\n    v4: ['k2'],\n    v5: ['k3']\n}"
      }
    ]
  },
  {
    component: Simple,
    name: 'Переворот массива из массивов с ключами впереди',
    text: 'Дан массив \\(a\\), состоящий из строковых массивов. Будем считать, что первый элемент - это ключ, остальные - значения. Создайте массив, у которого на первых местах будут значения из исходных массивов, а на последующих - ключи \\(a\\), которые содержали это значение',
    note: null,
    input: 'Массив \\(a\\)',
    output: 'Получившийся массив',
    examples: [
      {
        input: "[\n    ['k1', 'v1', 'v2', 'v3'],\n    ['k2', 'v2', 'v4'],\n    ['k3', 'v3', 'v5']\n]",
        output: "[\n    ['v1', 'k1'],\n    ['v2', 'k1', 'k2'],\n    ['v3', 'k1', 'k3'],\n    ['v4', 'k2'],\n    ['v5', 'k3']\n]"
      }
    ]
  },
  {
    component: Simple,
    name: 'Средняя и максимальная зарплата по отделам',
    text: "Дан массив \\(a\\), состоящий из объектов. В каждом объекте есть ключи 'department', 'name' и 'salary'. Создайте объект, у которого ключами будут значения 'department', а значениями объекты, у которых по ключу 'avg' должна быть средняя 'salary' в данном 'department', а по ключу 'best' массив из значений 'name', у которых самая большая 'salary' в данном 'department'",
    note: 'Порядок ключей не важен',
    input: 'Массив \\(a\\)',
    output: 'Получившийся объект',
    examples: [
      {
        input: "[\n    { department: 'one', name: 'Vasya', salary: 10000 },\n    { department: 'two', name: 'Petya', salary: 12000 },\n    { department: 'one', name: 'Misha', salary: 9000 },\n    { department: 'two', name: 'Fedor', salary: 12000 },\n    { department: 'three', name: 'Olga', salary: 9000 },\n    { department: 'three', name: 'Ivan', salary: 10000 },\n    { department: 'three', name: 'Anton', salary: 8000 }\n]",
        output: "{\n    one: {\n        avg: 9500,\n        best: ['Vasya']\n    },\n    two: {\n        avg: 12000,\n        best: ['Petya', 'Fedor']\n    },\n    three: {\n        avg: 9000,\n        best: ['Ivan']\n    }\n}"
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Преобразование в JSON',
    text: 'Напишите функцию \\(toJSON\\), которая принимает значение любого типа (кроме функций) и преобразует его в строку формата JSON. Для этого использовать встроенный метод JSON.stringify',
    note: null,
    examples: [
      {
        input: `
          const a = { name: 'Vasya', age: 20 }
          const s1 = toJSON(a)
          console.log(typeof s1)
          console.log(s1)

          const b = [a, a]
          const s2 = toJSON(b)
          console.log(typeof s2)
          console.log(s2)
        `,
        output: `
          string
          {"name":"Vasya","age":20}
          string
          [{"name":"Vasya","age":20},{"name":"Vasya","age":20}]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Преобразование из JSON',
    text: 'Напишите функцию \\(fromJSON\\), которая принимает строку формата JSON и возвращает значение, которое в ней закодировано. Для этого использовать встроенный метод JSON.parse',
    note: null,
    examples: [
      {
        input: `
          const s1 = '{"name":"Vasya","age":20}'
          const a = fromJSON(s1)
          console.log(typeof a)
          console.log(a.name, a.age)

          const s2 = '[{"name":"Vasya","age":20},{"name":"Petya","age":30}]'
          const b = fromJSON(s2)
          console.log(b.length)
          console.log(b[0].name, b[0].age, b[1].name, b[1].age)
        `,
        output: `
          object
          Vasya 20
          2
          Vasya 20 Petya 30
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    label: '+',
    name: 'Функция как поле объекта',
    text: "Напишите функцию \\(setHandlers\\), принимающую объекты \\(a\\) и \\(b\\). Запишите в объект \\(a\\) по ключу 'onclick' функцию, которая ничего не принимает и выводит текст 'a is clicked'. Также в \\(a\\) по ключу 'onkeydown' запишите функцию, которая принимает объект, в котором есть ключ 'key'. Пусть эта функция выводит текст 'Key {key} is down on a' (вместо {key} подставить значение из параметра). Также в объект \\(b\\) по ключу 'onclick' запишите функцию, которая ничего не принимает и выводит текст 'b is clicked'",
    note: null,
    examples: [
      {
        input: `
          const a = {}
          const b = {}
          setHandlers(a, b)
          a.onclick()
          b.onclick()
          a.onkeydown({ key: 'w' })
          a.onkeydown({ key: 'Space' })
        `,
        output: `
          a clicked
          b clicked
          Key w is down on a
          Key Space is down on a
        `
      }
    ]
  },
  {
    component: ContextIntro,
    label: '!'
  }
]

// ?агрегация многомерных рекурсивных данных
